import { Typography } from '@mui/material';
import React, { useEffect } from 'react';
import Layout from 'src/components/Layout/Page';
import { Box } from '@mui/system';
import { pageNotFoundPageLogger } from 'utils/loggers';

const NotFoundPage = () => {
  useEffect(() => {
    pageNotFoundPageLogger.view();
  }, []);

  return (
    <Layout title="페이지를 찾을 수 없습니다">
      <Box
        sx={{
          height: '80vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Box
          component="img"
          src="https://assets.lubycon.io/illusts/error.png"
          alt=""
          sx={{ width: '100%', maxWidth: '300px' }}
        />
        <Typography variant="h5">404 NOT FOUND</Typography>
      </Box>
    </Layout>
  );
};

export default NotFoundPage;
